body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'OpenSans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    font-family: 'Montserrat';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #aeaeae;
}

.home-list-image {
    object-fit: cover;
    width: 100%;
    height: 20vh;
}

.gallery-image-full {
    max-width: 100%;
    height: auto;
    max-height: 85vh;
}

hr {
    border: 0;
    width: 30px;
    height: 0;
    box-shadow: 0 0 3px 1px #666666;
    margin: 30px;
}

hr:after {
    content: "\00a0";
}

a {
    text-decoration: none;
    color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.padding10 {
    padding: 10px;
}

.padding20 {
    padding: 20px;
}

.protocolPagesSelector {
    padding-bottom:5px;
}

.leftButton {
    display: block;
    width: 100%;
    text-align: left;
}

.dontBreak {
    page-break-inside: avoid;
}